<template>
    <div class="col-12 mt-3">
        <WordFormComponent />
        <ModalUsersDinamics/>
        <router-link :to="{ name: 'dynamics' }" class="btn btn-outline-secondary btn-sm pl-4 pr-4">
            <i class="fa-solid fa-arrow-left"></i>
            Regresa
        </router-link>
        <div class="card mt-3">
            <div class="card-header">
                <h3 class="card-title">Lista de palabras - ({{ getTypeText() }})</h3>
                <button class="btn btn-sm btn-success float-right" v-if="authUser.profiles_id == 1" @click="showModalWord('')">
                    <i class="fa fa-plus"></i> Agregar
                </button>


            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12" v-if="authUser.profiles_id == 2">

                        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" @click="search.user_id = authUser.id" data-toggle="pill"
                                    role="tab" aria-selected="true">Para mi</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="search.user_id = ''" data-toggle="pill" role="tab"
                                    aria-selected="false">Generales</a>
                            </li>
                        </ul>
                    </div>                    
                </div>

                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th v-if="id == 1">Mensaje</th>
                                <th>Categoría</th>
                                <th>Palabra</th>
                                <th>Estatus</th>
                                <th>Tiempo</th>
                                <th v-if="authUser.profiles_id == 1">Usuario</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="words.data.length > 0">
                            <tr v-for="(word, index) in words.data" :key="index">
                                <td>{{ word.id }}</td>
                                <td v-if="id == 1">{{ word.message }}</td>
                                <td>{{ word.category.name }}</td>
                                <td>{{ word.data.word }}</td>
                                <td>
                                    <h6>
                                        <span class="badge"
                                            :class="word.statuses_id == 1 ? ' badge-success' : ' badge-danger'">{{
                                                word.status.name }}</span>
                                    </h6>
                                </td>
                                <td>{{ word.data.time }}</td>
                                <th v-if="authUser.profiles_id == 1">
                                    <span v-if="word.user">
                                        {{ word.user.name }}
                                    </span>
                                    <span v-else>
                                        General
                                    </span>
                                </th>
                                <td>
                                    <button @click=showModalWord(word.id) v-if="authUser.profiles_id == 1"
                                        class="btn btn-primary btn-sm">
                                        <i class="fas fa-edit "> </i>
                                    </button>
                                    <button type="button" v-if="authUser.profiles_id == 1"
                                        @click="deleted(word.id)" class="m-2 btn-danger btn btn-sm ">
                                        <i class="fa fa-trash"></i>
                                    </button>                                  
                                    
                                    <button @click=showModalWordDisabled(word.id) v-else type="button"
                                        class="btn btn-secondary btn-sm">
                                        <i class="fas fa-eye "> </i>
                                    </button>

                                    <button @click=onChangeValue(word.id)  v-if="authUser.profiles_id == 2 " type="button"
                                        class="btn btn-sm m-1" :class="search.user_id != '' ? 'btn-danger':'btn-success' ">
                                        <i class="fas" :class="search.user_id != '' ? 'fa-trash':'fa-plus' "> </i>
                                    </button>

                                    <button class="btn btn-info btn-sm" v-if="authUser.profiles_id == 1" @click="showUserDinamics(word)">
                                        <i class="fa fa-users"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td align="center" :colspan=" authUser.profiles_id == 2 ? '7' : '8'">Sin resultados.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-12">
						<div class="row">
							<div class="col-md-6 col-12 text-left mt-md-2">
								<strong> Mostrando del {{ words.from }} al {{ words.to }} de {{ words.total }} </strong>
							</div>
							<div class="col-md-6 col-12 text-right">
								<Pagination :prevButtonText="'Anterior'" :containerClass="'pagination'" :nextButtonText="'Siguiente'" :changePage="list" :data="words"/>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WordFormComponent from '@/components/Dinamics/WordFormComponent.vue';
import request from '../../../services/request';
import Catalogue from '../../../services/catalogue';
import ModalUsersDinamics from '@/components/Dinamics/ModalUsersDinamicsComponents.vue';

export default {
    name: "list-word-events",
    components: {
        WordFormComponent,
        ModalUsersDinamics
    },
    data() {
        return {
            id: '',
            search: {
                name: '',
                user_id: '',
            },
            user_serach: '',
            catalogue: new Catalogue(),
            words: {
                data: []
            }
        }
    },
    methods: {
        getTypeText() {
            let text = '';
            let id = this.id;
            if (id) {
                let types = this.catalogue.dynamic_types;
                text = types.filter(function (value) { return value.id == id });
                if (text.length > 0) {
                    return text[0].name;
                }
            }
        },
        async onChangeValue(dynamic_id) {
            let form = {
                user_id: this.authUser.id,
                dynamic_id: dynamic_id
            };
            let _endpoint = '/words_users';
            let service = request;
            let { data } = await service.post(_endpoint, form);
            if (data) {
                this.$snotify.success(data.message);    
                this.list();
            }
        },
        clearFilter() {
            this.user_serach = '';
            this.$refs.selectedItems.deactivate();
        },
        showUserDinamics(dynamic){            
            Event.$emit('event-users-dinamyc', dynamic);
        },
        showModalWord(id) {
            Event.$emit('event-new-word-events', id);
            this.$modal.show('NewWordDinamic');
        },
        showModalWordDisabled(id) {
            Event.$emit('event-new-word-events-disabled', id);
            this.$modal.show('NewWordDinamic');
        },
        deleted: function (id) {
            let tit = 'Eliminar dinámica';
            let txt = 'Seguro que quiere eliminar la dinámica';

            let vm = this;
            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    vm.callDeleted(id);
                }
            });
        },
        validAuth(user_id) {
            let profile = this.authUser.profiles_id;
            if (profile == 2) {
                if (user_id == this.authUser.id) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        async callDeleted(id) {
            let _endpoint = `${this.id}/dinamics/${id}`;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async list(page = 1) {
            let service = request;
            let search = this.search;
            let _endpoint = `${this.id}/dinamics`;
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    this.words = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            if (this.authUser.profiles_id == 2) {
                this.search.user_id = this.authUser.id;
            }
            this.catalogue.get_moderators();
            this.catalogue.get_dynamic_types();
            this.list();
        }
    },
    created() {
        Event.$on("event-load-word-events", () => {
            this.list();
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        "user_serach": function (value) {
            if (value) {
                this.search.user_id = value.id;
            } else {
                this.search.user_id = '';
            }
            this.list();
        },
        "search.user_id": function () {
            this.list();
        }
    },
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: #cd2001;
    color: #fff;
}

a {
    color: #cd2001;
    cursor: pointer;
}
.btn-fil{
    border-radius: 0px;
}
</style>

